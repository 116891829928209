import * as actionTypes from './actionsTypes';
import { APICore, setAuthorization } from '../../helpers/api/apiCore';
import axios from "axios";
import config from "../../config";


const api = axios.create({
    baseURL: config.API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const apiCore = new APICore();


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error
    };
};

export const authSuccess = (
    token,
    userId,
    name,
    email,
    tipo
) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token,
        userId,
        email,
        name,
        tipo
    }
}

export const logoutStart = () => {
    return {
        type: actionTypes.LOGOUT_START
    }
};

export const logoutSuccess = () => {
    return {
        type: actionTypes.LOGOUT_SUCCESS
    }
};

export const logoutFail = () => {
    return {
        type: actionTypes.LOGOUT_FAIL
    }
};

export const attData = (
    token,
    userId,
    name,
    email,
    tipo
) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token,
        userId,
        name,
        email,
        tipo
    }
}

export const auth = (user, password) => {
    return async dispatch => {
        const autData = new FormData();
        autData.append('email', user);
        autData.append('password', password);
        await apiCore.create('/auth', autData)
            .then(response => {
                const user = response.data.data.user;

                const result = {
                    userId: user.id,
                    userName: user.name,
                    token: user.token,
                    tipo: user.tipo_id,
                };

                apiCore.setLoggedInUser(result);

                setAuthorization(user.token);
                localStorage.setItem('user', result);
                sessionStorage.setItem('token', user.token);
                sessionStorage.setItem('userId', user.id);
                sessionStorage.setItem('userName', user.name);
                sessionStorage.setItem('email', user.email);
                sessionStorage.setItem('tipo_id', user.tipo_id);
                dispatch(authSuccess(
                    user.token,
                    user.id,
                    user.name,
                    user.email,
                    user.tipo_id
                ));
            }).catch(err => {
                console.log(err);
                dispatch(authFail(err.response.data.error));
            });
    }
};

export const logout = (token = null) =>  {
    
    return dispatch => {
        dispatch(logoutStart());
        if(token !== null) {
            apiCore.delete('/logout').then(meResponse => {
                sessionStorage.clear();
                dispatch(logoutSuccess())
            }).catch(err => {
                console.log(err);
                sessionStorage.clear();
                dispatch(logoutSuccess())
            });
        } else {
            sessionStorage.clear();
            dispatch(logoutSuccess())
        }
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = sessionStorage.getItem('token');

        if (!token){
            dispatch(logout());
        } else {
            const userId = sessionStorage.getItem('userId');
            const userName = sessionStorage.getItem('userName');
            const email = sessionStorage.getItem('email');
            const tipo = sessionStorage.getItem('tipo_id');

            dispatch(authSuccess(
                token,
                userId,
                userName,
                email,
                tipo
            ));
        }
    }
};

export const attAuthData = (data) => {
    return async dispatch => {
        const result = {
            userId: data.id,
            userName: data.name,
            email: data.email,
            tipo: data.tipo
        };
        const token = sessionStorage.getItem('token');
        localStorage.setItem('user', result);
        sessionStorage.setItem('userName', data.name);
        sessionStorage.setItem('email', data.email);
        sessionStorage.setItem('tipo_id', data.tipo);


        dispatch(authSuccess(
            token,
            data.id,
            data.name,
            data.email,
            data.tipo,
        ));
        authCheckState();
    }
}
