import React, {Suspense, useEffect} from "react";
import { connect } from 'react-redux';

import {Route, Routes, useNavigate} from "react-router";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Spinner from "./views/Components/Spinner/Spinner";
import { authCheckState } from "./store/actions";

const Login = React.lazy(() => import('./views/Pages/Login'))
//const System = React.lazy(() => import('./views/Pages/System'))
const Sistema = React.lazy(() => import('./views/Pages/Sistema'))

const App = props =>{
    const { onTryAutoSignup } = props;
    const navigate = useNavigate();
    let route = null;

    useEffect(() => {
        onTryAutoSignup();
    }, [onTryAutoSignup]);

    if(props.isAuthenticated){
        route = <Sistema {...props}/>
    }else{
        route = <Login />
    }

    return (
        <Suspense fallback={<Spinner/>}>
            <Routes>
                <Route  path="*" element={route}/>
            </Routes>
        </Suspense>
    );
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        isAuthenticated: state.auth.token !== null
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(authCheckState())
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

